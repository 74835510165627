import {
  IAuctionWhereIAmInvolved,
  IChangeInformations,
  IChangePassowrd,
  IChangePhoneNumber,
  IChangePrivacy,
  IOfferReceived,
  IProperty,
  IPropertyWithPositionAndList,
  ISaveClientFeedback,
  IUser,
} from '@kaaja/data-models';
import { PaymentIntent } from '@stripe/stripe-js';
import { config } from 'Shared/Services/environment.service';
import axios from 'axios';
import { createSessionFromCookie } from 'loop/context';
import { Question } from 'src/components/molecules/desktop-pdp/components/book-visit/second-step';
import { EstimatorHubspotFormFields } from 'src/core/estimator/estimator.models';
import { ILocations } from 'src/core/http/locations/model';
import { orderImagesAndPlanimetries } from 'src/core/http/property';

export async function createFetchRequest<T>(url: string, options?: RequestInit): Promise<T> {
  const baseUrl = config('NX_NEXT_PUBLIC_API_URL');

  const response = await fetch(`${baseUrl}${url}`, {
    ...options,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'API-Key-Kaaja': config('NX_NEXT_PUBLIC_KAAJA_API_KEY'),
      'Referer-Partner': createReferer(),
      ...createAuthorizationHeader(),
      ...options?.headers,
    },
  });

  if (!response.ok) {
    const text = await response.text();
    throw new Error(`Network response was not ok: ${text}`);
  }

  // return response.json();

  /** This is a porkaroud to fix the APIs which do not return JSON. (Without any reason..) */
  const responseAsText = await response.text();

  try {
    return JSON.parse(responseAsText);
  } catch (error) {
    return responseAsText as unknown as T;
  }
}

const createReferer = () => {
  const canUseDOM = !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );

  if (!canUseDOM) {
    switch (config('NX_NEXT_PUBLIC_ENV')) {
      case 'prd':
        return 'kaaja.com';
      case 'demo':
        return 'kaajab.com';
      case 'dev':
        return 'kaajab.com';
      default:
        throw new Error('Unknown Environment..');
    }
  }

  const url =
    window.location !== window.parent?.location ? document.referrer : document.location.href;

  if (url.includes('localhost') || url.includes('127.0.0.1') || url.includes('0.0.0.0')) {
    return 'kaajab.com';
  }

  return new URL(url).host.split('.').slice(-2).join('.').split(':')[0];
};

/** Todo: Abstraction for language (cookie?) */
// Esportare questa funzione, ed usarla in modo che accetti il locale di next, cosi creo queta
export function createLanguageHeader(currentLocale?: string) {
  return {
    'Content-Language': currentLocale === 'it' ? 'it-IT' : 'en-US',
  };
}

export function createApiKeyHeader(override: string | string[] | undefined) {
  if (!override || typeof override !== 'string') {
    return { 'API-Key-Kaaja': config('NX_NEXT_PUBLIC_KAAJA_API_KEY') };
  }

  return { 'API-Key-Kaaja': override || '' };
}

function createAuthorizationHeader() {
  const canUseDOM = !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );

  if (!canUseDOM) return null;

  const session = createSessionFromCookie();

  if (!session) return null;

  return {
    Authorization: session.accessToken.value,
    // 'api-token-info-kaaja': encodeToken({
    //   token: session.accessToken.value,
    //   refreshToken: session.refreshToken,
    // }), //session?.accessToken.value,
  };
}

export async function addFavorite(pid: string) {
  return createFetchRequest(`/public/api/users/favorite_property/${pid}`, {
    method: 'POST',
  });
}

export async function deleteFavorite(pid: string) {
  return createFetchRequest(`/public/api/users/favorite_property/${pid}`, {
    method: 'DELETE',
  });
}

export async function fetchFavorite(pid: string) {
  return createFetchRequest<{ isFavorite: boolean }>(`/public/api/v2/favorites/${pid}`, {
    method: 'GET',
  });
}

export async function fetchV1Locations(opts?: RequestInit) {
  return createFetchRequest<ILocations[]>('/public/api/locations', {
    method: 'GET',
    headers: {
      ...opts?.headers,
    },
  });
}

export async function fetchUserAuctions(
  uid: string,
  params: { status: string },
  opts?: RequestInit,
) {
  return createFetchRequest<IAuctionWhereIAmInvolved[]>(
    `/public/api/users/${uid}/auctions/${params.status}`,
    {
      method: 'GET',
      headers: {
        ...opts?.headers,
      },
    },
  );
}

export async function createBid(aid: string, data: unknown, opts?: RequestInit) {
  return createFetchRequest<void>(`/public/api/auctions/${aid}/bid`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      ...opts?.headers,
    },
  });
}

export async function createBidBuyNow(aid: string, data: unknown, opts?: RequestInit) {
  return createFetchRequest<void>(`/public/api/auctions/${aid}/bid_buy_now`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      ...opts?.headers,
    },
  });
}

export async function createBidBuyNowOnly(aid: string, data: unknown, opts?: RequestInit) {
  return createFetchRequest<void>(`/public/api/auctions/${aid}/bid_buy_now_only`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      ...opts?.headers,
    },
  });
}

export async function changeAuctionStatus(aid: string, status: string) {
  return createFetchRequest<void>(`/public/api/auctions/${aid}/update-status`, {
    method: 'POST',
    body: JSON.stringify({ status }),
  });
}

export async function changeUserPassword(data: IChangePassowrd) {
  return createFetchRequest<void>('/public/api/change_password', {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export async function changeUserInfo(data: IChangeInformations) {
  return createFetchRequest<void>('/public/api/change_informations', {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export async function changeUserPhone(data: IChangePhoneNumber) {
  return createFetchRequest<void>('/public/api/change_phone_number', {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export async function changeUserPrivacy(data: IChangePrivacy) {
  return createFetchRequest<void>('/public/api/change_privacy', {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export async function fetchPidByCode(code: string, opts?: RequestInit) {
  return createFetchRequest<string | null>(`/public/api/properties/code/${code}`, {
    method: 'GET',
    headers: {
      ...opts?.headers,
    },
  });
}

export async function fetchProperty(pid: string, opts?: RequestInit) {
  const res = await createFetchRequest<IProperty>(`/public/api/properties/${pid}`, {
    method: 'GET',
    headers: {
      ...opts?.headers,
    },
  });

  /** This will be removed */
  return orderImagesAndPlanimetries(res);
}

export async function fetchUserFavorites(uid: string, opts?: RequestInit) {
  const res = await createFetchRequest<IProperty[]>(
    `/public/api/users/${uid}/favorite_properties`,
    {
      method: 'GET',
      headers: {
        ...opts?.headers,
      },
    },
  );

  /** This will be removed */
  return res
    .map((p) => orderImagesAndPlanimetries(p))
    .map((p, index) => ({ ...p, position: index + 1 }));
}

export async function createInfoRequest(data: Record<string, unknown>, opts?: RequestInit) {
  return createFetchRequest<void>('/public/api/request-information/property', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      ...opts?.headers,
    },
  });
}

export async function fetchPaymentIntent(aid: string, opts?: RequestInit) {
  type Response = { client_key: string; status: PaymentIntent.Status } | undefined;

  try {
    const res = await createFetchRequest<Response>(`/public/api/auctions/${aid}/payment-intent`, {
      method: 'GET',
      headers: {
        ...opts?.headers,
      },
    });

    return res;
  } catch (err) {
    return undefined;
  }
}

export async function createHoldFee(
  aid: string,
  data: Record<string, unknown>,
  opts?: RequestInit,
) {
  type Response = { client_secret: string } | undefined;

  return createFetchRequest<Response>(`/public/api/auctions/${aid}/hold-deposit-payment`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      ...opts?.headers,
    },
  });
}

export async function fetchLocation(loc: string, opts?: RequestInit) {
  type Location = {
    type: string;
    language: string;
    name: string;
    slug: string;
  };

  return createFetchRequest<Location[]>(`/public/api/v2/locations/${loc}`, {
    method: 'GET',
    headers: {
      ...opts?.headers,
    },
  });
}

export async function createOfferReceived(data: IOfferReceived, opts?: RequestInit) {
  try {
    const oid = createFetchRequest<string>(`/public/api/offer-received`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        ...opts?.headers,
      },
    });

    return oid;
  } catch {
    return undefined;
  }
}

export async function confirmOfferReceived(oid: string, opts?: RequestInit) {
  return createFetchRequest<void>(`/public/api/offer-received/${oid}/confirmed`, {
    method: 'PUT',
    headers: {
      ...opts?.headers,
    },
  });
}

export async function createFeedback(data: ISaveClientFeedback, opts?: RequestInit) {
  return createFetchRequest<void>(`/public/api/feedbacks`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      ...opts?.headers,
    },
  });
}

export async function fetchUserByUsername(id: string, options?: RequestInit) {
  return createFetchRequest<IUser>(`/public/api/users/${id}`, {
    method: 'GET',
    headers: {
      ...options?.headers,
    },
  });
}

export async function createEvaluation(data: EstimatorHubspotFormFields) {
  return createFetchRequest<IUser>(`/public/api/evaluations`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export async function fetchHomeProperties(options?: RequestInit) {
  return createFetchRequest<IPropertyWithPositionAndList[]>(`/public/api/properties`, {
    method: 'GET',
    headers: {
      ...options?.headers,
    },
  });
}

export async function fetchQuestions(sid: string, opts?: RequestInit) {
  const response = await axios.get<Question[]>(`/v2/timify/${sid}/questions`, {
    baseURL: config('NX_NEXT_PUBLIC_API_URL'),
  });

  return response.data;
}
