import { IFile } from '@kaaja/data-models';

export const orderByTitleAsInteger = (list: IFile[]): IFile[] => {
  const getIntegerFromTitle = (title: string): number => {
    const num = parseInt(title, 10);
    return isNaN(num) ? 0 : num;
  };
  return [...list].sort(
    (a: IFile, b: IFile) =>
      getIntegerFromTitle(a.title) - getIntegerFromTitle(b.title)
  );
};
